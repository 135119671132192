/*
 * @Author: SongYijie
 * @Date: 2020-04-02 14:24:13
 * @LastEditors: SongYijie
 */
import service, { servicePre } from '@utils/request'

// 获取验证码
const getVeriCode = (data) => {
  return servicePre({
    url: '/front/authController/sendCode',
    method: 'post',
    data: data
  })
}

// 登录
const loginning = (data) => {
  return servicePre({
    url: '/front/authController/login',
    method: 'post',
    data: data
  })
}

// 输入身份证
const inputIdNum = (data) => {
  return servicePre({
    url: '/front/authController/getUserInfoAndIdNum',
    method: 'post',
    data: data
  })
}

// 微信解绑
const unitWeChat = () => {
  return service({
    url: 'front/personalController/untieWeChat',
    method: 'post'
  })
}

// 微信登录
const loginByWXCode = data => {
  return service({
    url: '/front/authController/weChatLogin',
    method: 'post',
    data
  })
}

export { getVeriCode, loginning, inputIdNum, unitWeChat, loginByWXCode }

<template>
  <div class="page-bg">
    <div class="user-pannel clearfix">
      <div class="user-face">
        <img v-if="!isXyk" src="@assets/images/user-face.png" />
        <img v-else src="@assets/ql_logo.png" />
      </div>
      <div>
        <div class="txt-gray userId">{{userId}}</div>
        <div class="txt-gray-lighter userState">
          <img class="icon" src="@assets/images/aboutUs/icon-renzheng.png" alt />
          <p v-if="userState == '2'">已实名认证</p>
          <p v-else>未完成实名认证</p>
        </div>
      </div>
    </div>
    <div class="menu">
      <mt-cell title="用户信息" to="/aboutUs/userInfo" is-link value></mt-cell>
      <mt-cell title="流水明细" to="/user/turnover-list" is-link value></mt-cell>
      <mt-cell title="常见问题" to="/aboutUs/questions" is-link value></mt-cell>
      <mt-cell title="联系客服" to="/aboutUs/contact" is-link value></mt-cell>
      <mt-cell title="关于" to="/aboutUs/intro" is-link value></mt-cell>
      <mt-cell title="解绑微信" @click.native="handleUntieWeChat"></mt-cell>
      <mt-cell title="退出登录" @click.native="Cancellation" value></mt-cell>
    </div>
  </div>
</template>
<script>
import { Cancellation, getUserInfo } from "@api/user";
import { unitWeChat } from "@api/login";
import { Toast } from 'mint-ui'
export default {
  data() {
    return {
      userId: this.$store.getters.getUser.phone,
      userState: this.$store.getters.getUser.state,
      isXyk: window.location.origin.indexOf('h5.kylincloud-saas') > -1
    };
  },
  methods: {
    Cancellation() {
      //注销
      const authorization = this.$store.getters.getUser.authorization
      const userNo = this.$store.getters.getUser.userId
      if (
        authorization == undefined ||
        authorization == "" ||
        authorization == null
      )
        return
      if (userNo == undefined || userNo == "" || userNo == null) return
      let data = {
        authorization: authorization,
        userId: userNo
      };
      // 注销接口
      Cancellation(data)
        .then(res => {
          if (!res.data) {
            //处理失败
            this.err.code = res.data.message;
            Toast(res.data.message);
            return;
          }
          this.$store.commit('logout')
          this.$router.push("/login")
        })
        .catch(err => {
        });
    },
    handleUntieWeChat() {
      unitWeChat().then(res => {
        if (res.code === 200) {
          Toast('解绑微信成功');
        } else {
          Toast(res.message);
        }
      })
    }
  },
  mounted () {
    let data = {
      userNo : this.$store.getters.getUser.userId,
      Authorization : this.$store.getters.getUser.authorization
    }
    getUserInfo(data).then((res) => {
      if (res.code === 200) {
        const { data } = res;
        this.userId = data.userMobile;
        this.userState = data.state;
        const userInfo = { ...this.$store.getters.getUser, state: data.state }
        this.$store.commit('setUser', userInfo);
        sessionStorage.setItem('user', JSON.stringify(userInfo))
      }
    }).catch((err) => {
      
    });

  }
};
</script>
<style lang="less" scoped>
.page-bg {
  background: url("./../../assets/images/aboutUs/bg.png") no-repeat #fff;
  background-size: 100% auto;
  background-position: 0 0;
  height: 100%;
  padding-top: 27%;

}
.user-pannel {
  margin: 20px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px 15px;
  box-shadow: 0px 0px 15px 7px rgba(0, 0, 0, 0.1);
}
.user-face {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #eee;
  float: left;
  margin-right: 15px;
}
.userId {
  padding-top: 5px;
  margin-bottom: 5px;
}
.userState {
  font-size: 0.8rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.icon {
  width: 1rem;
  display: inline-block;
  float: left;
  margin-right: 5px;
}
.menu {
  padding: 15px 15px 120px 15px;
  a,
  * {
    color: #333;
  }
}
.mint-cell-allow-right::after {
  width: 8px;
  height: 8px;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}
</style>

/*
 * @Author: SongYijie
 * @Date: 2020-03-27 09:49:22
 * @LastEditors: SongYijie
*/
import service, { serviceFormData } from '@utils/request'

// 获取用户信息
const getUserInfo = (data) => {
  return service({
    url: '/front/userTaskController/getUserInfo',
    method: 'post',
    data: data
  })
}

/**
 * 上传身份证
 * @param {*} data 
 * @param {*} callback1 
 */
const uploadIdCard = (data, callback1) => {
  data.isFormData = true;
  return service({
    url: '/front/personalController/idCard',
    method: 'post',
    data: data,
    onUploadProgress: function (progressEvent) {
      // 原生获取上传进度的事件
      if (progressEvent.lengthComputable) {
        // 属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
        // 如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
        callback1(progressEvent)
      }
    }
  })
}
// 注销登录
const Cancellation = (data) => {
  return service({
    url: '/front/authController/logout',
    method: 'post',
    data: data
  })
}
// 流水明细
const getTurnoverList = data => {
  return service({
    url: '/front/paymentDetailController/h5PaymentDetailList',
    method: 'post',
    data
  })
}

// 特殊用户三要素验证
const tempBankCardCheck = data => {
  return service({
    url: '/front/userInfoController/bankCardCheck',
    method: 'post',
    data
  })
}

// 港澳台证件上传
const uploadCardImage = data => {
  return serviceFormData({
    url: '/front/personalController/uploadImage',
    method: 'post',
    data
  })
}

// 添加港澳台用户
const applyUserInfo = data => {
  return service({
    url: '/front/personalController/addUserInfo',
    method: 'post',
    data
  })
}

// 港澳台详情
const getApplyUserInfo = data => {
  return service({
    url: '/front/personalController/getUserInfoApplyDetails',
    method: 'post',
    data
  })
}

// 获取客服聊天窗接口
const getChatUrl = data => {
  return service({
    url: 'front/customerInfo/encrypt',
    method: 'post',
    data
  })
}

// 视频上传
const uploadVideoFile = data => {
  return serviceFormData({
    url: '/front/selfEE/uploadVideoFile',
    method: 'post',
    data
  })
}

// 绑定openID
const accessCode = data => {
  return service({
    url: '/front/employ/accessCode',
    method: 'post',
    data
  })
}

// 人脸视频关联个体户
const uploadFaceVideo = data => {
  return service({
    url: '/front/selfEE/uploadFaceVideo',
    method: 'post',
    data
  })
}

export { 
  getUserInfo,
  Cancellation,
  uploadIdCard,
  getTurnoverList,
  tempBankCardCheck,
  uploadCardImage,
  applyUserInfo,
  getApplyUserInfo,
  getChatUrl,
  uploadVideoFile,
  accessCode,
  uploadFaceVideo
}
